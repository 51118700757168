<style lang="scss" scoped>
    .container {
        background-color: #f8f8f8;
    }

    .form-inline {
        padding-top: 10px;
        background-color: #fff;
        padding-left: 16px;
        margin-bottom: 10px;
    }

    .pagination-container {
        text-align: right;
        margin-top: 10px;
    }

    .warning-color {
        color: #FF3B30;
    }
</style>
<template>
    <div>
        <open-audit service-type="0"></open-audit>
    </div>

</template>

<script type="text/ecmascript-6">
    import openAudit from "../openAudit/index"
    export default {
        //定义模版数据
        data() {
            return {

            }
        },
        //计算属性
        computed: {

        },
        components: {
            openAudit
        },
        //主件被加载完成
        mounted: function () {

        },
        //定义事件方法
        methods: {

        },
        //监听模版变量
        watch: {}

    }
</script>
